<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';

export default {
  page: {
    title: "Vendor List",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  setup () {
    
    const tableColumns = [
      //this.$t('fields.port.name')
      { key: "name", label: 'Name', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "address", label: "Address", thClass: 'text-center', tdClass: 'text-center',  sortable: true },
      { key: "contact_name", label: 'Contact Name', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "contact_phone", label: 'Phone', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "vnd_capacitydef", label: 'Load', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "action",label: "Edit", tdClass: "text-center" }
      //{ key: "porttimezone", label: "Time Zone", sortable: true }
    ]
    
    // Table Handlers
    const columnToBeFilter = [
      'name',
      'address',
      'contact_name',
      'contact_phone'
    ]

    return {
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: 'master/vendor'
      }),
    }
  },
  data() {
    return {
      title: "Vendor List",
      items: []
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalList = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-button href="/masterdata/vendor/add" variant="light">
              <i class="ri-add-line align-middle mr-2"></i> {{ $t('global.button.add') }}
            </b-button>
            <div class="row mt-4">
               <div class="col-sm-12 col-md-6">
                
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                no-provider-paging
                no-provider-filtering
                :filter-included-fields="columnToBeFilter"
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
              >
               <template v-slot:cell(action)="data">
                  <router-link :to="{ name: 'appsmd-vendor-edit', params: {vendor_id : data.item.id } }">
                                        <i class="ri-edit-2-line"></i>

                  </router-link>
                 
                </template>
                </b-table>
            </div>
           <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                   Showing
                    <b-form-select class="col-sm-12 col-md-2" v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                  </label>
                </div>
              </div>

              <div class="col-md-6 col-xs-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalList"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>